import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107')
];

export const server_loads = [0,2,6,8,14,15];

export const dictionary = {
		"/": [~16],
		"/app": [~19,[2]],
		"/app/academic/class-placement": [~20,[2,3]],
		"/app/academic/classes": [~21,[2,3]],
		"/app/academic/classes/[classId]": [~22,[2,3]],
		"/app/academic/continuation": [~23,[2,3]],
		"/app/academic/overview": [~24,[2,3]],
		"/app/admin/academic-years": [~25,[2,4]],
		"/app/admin/academic-years/[academicYearId]": [~26,[2,4]],
		"/app/admin/delete-students": [27,[2,4]],
		"/app/admin/users": [~28,[2,4]],
		"/app/admin/users/[staffId]": [~29,[2,4]],
		"/app/admin/value-centers": [~30,[2,4]],
		"/app/admin/value-centers/[valueCenterId]": [~31,[2,4]],
		"/app/admission/admission-groups": [32,[2,5]],
		"/app/admission/admission-steps": [~33,[2,5]],
		"/app/admission/admission-steps/[stepId]": [~34,[2,5]],
		"/app/admission/announcements": [~35,[2,5]],
		"/app/admission/announcements/[announcementId]": [~36,[2,5]],
		"/app/admission/overview": [~37,[2,5]],
		"/app/admission/registrations": [~38,[2,5]],
		"/app/admission/reports": [39,[2,5]],
		"/app/admission/schools": [~40,[2,5]],
		"/app/admission/schools/[schoolId]": [~41,[2,5]],
		"/app/admission/students": [~42,[2,5]],
		"/app/admission/students/form/existing": [~43,[2,5]],
		"/app/admission/students/form/new": [~44,[2,5]],
		"/app/class/[classId]/sessions": [~45,[2,6]],
		"/app/class/[classId]/sessions/[sessionId]": [~46,[2,6]],
		"/app/class/[classId]/students": [~47,[2,6]],
		"/app/class/[classId]/timetable": [~48,[2,6]],
		"/app/finance/bills": [49,[2,7]],
		"/app/finance/bills/bill-templates": [~50,[2,7]],
		"/app/finance/bills/insert-bills": [~51,[2,7]],
		"/app/finance/monitoring": [~52,[2,7]],
		"/app/finance/overview": [~53,[2,7]],
		"/app/finance/payment-categories": [~54,[2,7]],
		"/app/finance/payment-categories/[paymentCategoryId]/discounts": [~55,[2,7]],
		"/app/finance/payment-categories/[paymentCategoryId]/discounts/[discountId]": [~56,[2,7]],
		"/app/finance/payment-categories/[paymentCategoryId]/form": [~57,[2,7]],
		"/app/finance/payment-categories/[paymentCategoryId]/payment-types": [~58,[2,7]],
		"/app/finance/payment-categories/[paymentCategoryId]/payment-types/[paymentTypeId]": [~59,[2,7]],
		"/app/finance/reports": [60,[2,7]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-day": [~61,[2,7]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-month": [~62,[2,7]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-student": [~63,[2,7]],
		"/app/finance/reports/[startDate]/[endDate]/receivables-per-student": [~64,[2,7]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-day": [~65,[2,7]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-month": [~66,[2,7]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-student": [~67,[2,7]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-transaction": [~68,[2,7]],
		"/app/finance/students": [~69,[2,7]],
		"/app/guardian/[guardianId]": [70,[2,8]],
		"/app/guardian/[guardianId]/biodata": [~71,[2,8]],
		"/app/home/announcements": [~72,[2,9]],
		"/app/home/announcements/[announcementId]": [~73,[2,9]],
		"/app/internal/document-categories": [~74,[2,10]],
		"/app/internal/document-categories/[documentCategoryId]": [~75,[2,10]],
		"/app/internal/document-tags": [~76,[2,10]],
		"/app/internal/document-tags/[documentTagId]": [~77,[2,10]],
		"/app/internal/documents": [~78,[2,10]],
		"/app/internal/documents/[documentTypeId]": [~79,[2,10]],
		"/app/internal/documents/[documentTypeId]/documents": [~80,[2,10]],
		"/app/internal/documents/[documentTypeId]/documents/[documentId]": [~81,[2,10]],
		"/app/internal/documents/[documentTypeId]/documents/[documentId]/viewer": [~82,[2,10]],
		"/app/inventory/assets": [83,[2,11]],
		"/app/inventory/categories": [84,[2,11]],
		"/app/inventory/kits": [85,[2,11]],
		"/app/inventory/locations": [86,[2,11]],
		"/app/inventory/tags": [87,[2,11]],
		"/app/people/guardians": [~88,[2,12]],
		"/app/people/guardians/new": [~89,[2,12]],
		"/app/people/staffs": [~90,[2,12]],
		"/app/people/students": [~91,[2,12]],
		"/app/settings/2fa": [~92,[2,13]],
		"/app/settings/2fa/disable": [~93,[2,13]],
		"/app/settings/2fa/verify": [~94,[2,13]],
		"/app/settings/change-password": [~95,[2,13]],
		"/app/staff/[staffId]": [96,[2,14]],
		"/app/staff/[staffId]/biodata": [~97,[2,14]],
		"/app/student/[studentId]": [98,[2,15]],
		"/app/student/[studentId]/bills": [~99,[2,15]],
		"/app/student/[studentId]/bills/[billId]": [~100,[2,15]],
		"/app/student/[studentId]/biodata": [~101,[2,15]],
		"/app/student/[studentId]/invoices": [~102,[2,15]],
		"/app/student/[studentId]/invoices/[invoiceId]/approve": [~103,[2,15]],
		"/app/student/[studentId]/invoices/[invoiceId]/reallocate": [~104,[2,15]],
		"/app/student/[studentId]/invoices/[invoiceId]/receipt/pdf": [~105],
		"/app/student/[studentId]/others": [~106,[2,15]],
		"/app/student/[studentId]/others/[noteId]": [~107,[2,15]],
		"/(auth)/login": [~17],
		"/(auth)/verify": [~18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';